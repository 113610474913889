import Matter from 'matter-js';

const matterContainer = document.querySelector('#m');
const WALL_THICKNESS = 100;

// module aliases
var Engine = Matter.Engine,
  Render = Matter.Render,
  Runner = Matter.Runner,
  Bodies = Matter.Bodies,
  Composite = Matter.Composite;

// create an engine
var engine = Engine.create();

// create a renderer
var render = Render.create({
  element: matterContainer,
  engine: engine,
  options: {
    width: matterContainer.clientWidth,
    height: matterContainer.clientHeight,
    background: 'transparent',
    wireframes: false,
    showAngleIndicator: false,
    pixelRatio: 'auto',
  },
});

var ground = Bodies.rectangle(
  matterContainer.clientWidth / 2,
  matterContainer.clientHeight + WALL_THICKNESS / 2,
  27184,
  WALL_THICKNESS,
  { isStatic: true },
);

let leftWall = Bodies.rectangle(
  0 - WALL_THICKNESS / 2,
  matterContainer.clientHeight / 2,
  WALL_THICKNESS,
  matterContainer.clientHeight * 5,
  {
    isStatic: true,
  },
);

let rightWall = Bodies.rectangle(
  matterContainer.clientWidth + WALL_THICKNESS / 2,
  matterContainer.clientHeight / 2,
  WALL_THICKNESS,
  matterContainer.clientHeight * 5,
  { isStatic: true },
);

// add all of the bodies to the world
Composite.add(engine.world, [ground, leftWall, rightWall]);
function boundsColor() {
  return '#FFF0';
}
// const colorRange = Math.floor(Math.random() * 315);
let h = Math.floor(Math.random() * 360);
function randColor() {
  // return '#FFF0';
  // return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  // const h = colorRange + Math.floor(Math.random() * 45);
  h++;
  if (h > 360) {
    h = 0;
  }
  const s = '100%';
  const l = '50%';
  return `hsl(${h},${s},${l})`;
}

function getLogoBounds() {
  let width = 800;
  maxWidth = 0.8 * matterContainer.clientWidth;
  // aspect ratio 800 x 141
  if (width > maxWidth) {
    width = maxWidth;
  }
  // const height = (141 / 800) * width;
  const height = (151 / 834) * width;
  const x = matterContainer.clientWidth / 2 - width / 2;
  const y = matterContainer.clientHeight / 2 - height / 2;
  return {
    x,
    y,
    width,
    height,
  };
}

const letters = [];

function translateBounds(x, y) {
  const bounds = getLogoBounds();
  return {
    x: (bounds.width * x) / 834,
    y: (bounds.height * y) / 151,
  };
}
function addLetters() {
  const bounds = getLogoBounds();
  const baseLeft = matterContainer.clientWidth / 2 - bounds.width / 2;
  const baseTop = matterContainer.clientHeight / 2 - bounds.height / 2;

  /* D */
  letters.push(
    Bodies.fromVertices(
      baseLeft + translateBounds(120, 0).x / 2,
      baseTop + translateBounds(60, 151).y / 2,
      [
        { x: 0, y: 0 },
        translateBounds(68, 0),
        translateBounds(84, 5),
        translateBounds(97, 12),
        translateBounds(109, 23),
        translateBounds(118, 33),
        translateBounds(125, 51),
        translateBounds(129, 66),
        translateBounds(129, 87),
        translateBounds(125, 103),
        translateBounds(118, 117),
        translateBounds(118, 117),
        translateBounds(109, 128),
        translateBounds(97, 139),
        translateBounds(84, 146),
        translateBounds(63, 151),
        translateBounds(0, 151),
      ],
      {
        isStatic: true,
        render: {
          fillStyle: boundsColor(),
        },
      },
    ),
  );

  function addBox(x, y, w, h) {
    letters.push(
      Bodies.rectangle(
        baseLeft + translateBounds(x, y).x + translateBounds(w, h).x / 2,
        baseTop + translateBounds(x, y).y + translateBounds(w, h).y / 2,
        translateBounds(w, h).x,
        translateBounds(w, h).y,
        {
          isStatic: true,
          render: {
            fillStyle: boundsColor(),
          },
        },
      ),
    );
  }

  /* E */
  addBox(161, 0, 94, 20); // top
  addBox(161, 0, 23, 151); // left
  addBox(182, 65, 62, 19); // middle
  addBox(182, 131, 73, 20); // bottom

  /* H */
  addBox(288, 0, 22, 151); // left
  addBox(310, 64, 79, 21); // middle
  addBox(389, 0, 22, 151); // right

  /* A */
  letters.push(
    Bodies.fromVertices(
      baseLeft +
        translateBounds(444, 151).x +
        translateBounds(72, 151).x * 0.54,
      baseTop + translateBounds(72, 151).y * 0.54,
      [
        translateBounds(444, 146),
        translateBounds(510, 0),
        translateBounds(516, 0),
        translateBounds(516, 41),
        translateBounds(468, 148),
        translateBounds(463, 151),
        translateBounds(446, 151),
      ],
      {
        isStatic: true,
        render: {
          fillStyle: boundsColor(),
        },
      },
    ),
  );
  letters.push(
    Bodies.fromVertices(
      baseLeft + translateBounds(516, 151).x + translateBounds(71, 151).x * 0.4,
      baseTop + translateBounds(71, 151).y * 0.54,
      [
        translateBounds(516, 0),
        translateBounds(521, 0),
        translateBounds(587, 148),
        translateBounds(584, 151),
        translateBounds(567, 151),
        translateBounds(564, 147),
        translateBounds(516, 42),
      ],
      {
        isStatic: true,
        render: {
          fillStyle: boundsColor(),
        },
      },
    ),
  );
  addBox(480, 100, 72, 18); // A-BAR

  /* R */
  let offsetLeft = 608;
  letters.push(
    Bodies.fromVertices(
      baseLeft +
        translateBounds(offsetLeft, 0).x +
        translateBounds(110, 0).x * 0.45,
      baseTop + translateBounds(0, 151).y * 0.3,
      [
        translateBounds(offsetLeft + 3, 0),
        translateBounds(offsetLeft + 71, 0),
        translateBounds(offsetLeft + 83, 4),
        translateBounds(offsetLeft + 92, 10),
        translateBounds(offsetLeft + 100, 17),
        translateBounds(offsetLeft + 105, 25),
        translateBounds(offsetLeft + 108, 35),
        translateBounds(offsetLeft + 110, 40),
        translateBounds(offsetLeft + 110, 53),
        translateBounds(offsetLeft + 107, 64),
        translateBounds(offsetLeft + 104, 70),
        translateBounds(offsetLeft + 98, 77),
        translateBounds(offsetLeft + 90, 84),
        translateBounds(offsetLeft + 81, 90),
        translateBounds(offsetLeft + 75, 92),
        translateBounds(offsetLeft, 92),
        translateBounds(offsetLeft, 3),
      ],
      {
        isStatic: true,
        render: {
          fillStyle: boundsColor(),
        },
      },
    ),
  );

  letters.push(
    Bodies.fromVertices(
      baseLeft +
        translateBounds(offsetLeft, 0).x +
        translateBounds(54, 0).x * 1.5,
      baseTop + translateBounds(72, 62).y * 1.95,
      [
        translateBounds(offsetLeft + 77, 89),
        translateBounds(offsetLeft + 108, 145),
        translateBounds(offsetLeft + 108, 149),
        translateBounds(offsetLeft + 105, 151),
        translateBounds(offsetLeft + 85, 151),
        translateBounds(offsetLeft + 54, 90),
      ],
      {
        isStatic: true,
        render: {
          fillStyle: boundsColor(),
        },
      },
    ),
  );
  addBox(607, 82, 22, 69); // top
  /* T */
  addBox(733, 0, 101, 20); // top
  addBox(772, 20, 24, 131); // left

  /* dot */
  letters.push(
    Bodies.circle(
      baseLeft + translateBounds(480, 0).x,
      -100,
      translateBounds(24, 0).x,
      {
        friction: 0.3,
        frictionAir: 0.000001,
        restitution: 0.75,
        render: {
          fillStyle: '#FFF',
        },
      },
    ),
  );
  Composite.add(engine.world, letters);
}

addLetters();

const bounds = getLogoBounds();
const baseLeft = matterContainer.clientWidth / 2 - bounds.width / 2;

let mouse = Matter.Mouse.create(render.canvas);
let mouseConstraint = Matter.MouseConstraint.create(engine, {
  mouse: mouse,
  constraint: {
    stiffness: 0.2,
    render: {
      visible: false,
    },
  },
});

Composite.add(engine.world, mouseConstraint);

// allow scroll through the canvas
mouseConstraint.mouse.element.removeEventListener(
  'mousewheel',
  mouseConstraint.mouse.mousewheel,
);
mouseConstraint.mouse.element.removeEventListener(
  'DOMMouseScroll',
  mouseConstraint.mouse.mousewheel,
);

// run the renderer
Render.run(render);

// create runner
var runner = Runner.create();

// run the engine
Runner.run(runner, engine);

function handleResize(matterContainer) {
  // set canvas size to new values
  render.canvas.width = matterContainer.clientWidth * 2;
  render.canvas.style.width = `${matterContainer.clientWidth}px`;
  render.canvas.height = matterContainer.clientHeight * 2;
  render.canvas.style.height = `${matterContainer.clientHeight}px`;

  // reposition ground
  Matter.Body.setPosition(
    ground,
    Matter.Vector.create(
      matterContainer.clientWidth / 2,
      matterContainer.clientHeight + WALL_THICKNESS / 2,
    ),
  );

  // reposition right wall
  Matter.Body.setPosition(
    rightWall,
    Matter.Vector.create(
      matterContainer.clientWidth + WALL_THICKNESS / 2,
      matterContainer.clientHeight / 2,
    ),
  );

  while (letters.length > 0) {
    Composite.remove(engine.world, letters.pop());
  }

  addLetters();
}

window.addEventListener('resize', () => handleResize(matterContainer));

let randDots = [];
function randDot() {
  const size = translateBounds(10 + Math.random() * 50, 0).x;
  // const size = translateBounds(30,0).x;
  let circle = Bodies.circle(
    matterContainer.clientWidth / 2 - 250 + Math.random() * 500,
    -100,
    size,

    {
      friction: 0.3,
      frictionAir: 0.00001,
      restitution: 0.8,
      render: {
        fillStyle: randColor(),
      },
    },
  );
  Composite.add(engine.world, circle);
  randDots.push(circle);
  if (randDots.length > 100) {
    shrinkAndRemove(randDots.shift());
  }
}
function shrinkAndRemove(b) {
  let scale = 1;
  let scaleV = 0;
  function update() {
    if (scale > 0.01) {
      requestAnimationFrame(update);
      scaleV += 0.01;
      scale -= scaleV;
      if (scale < 0) {
        scale = 0;
      }
      Matter.Body.scale(b, scale, scale);
    } else {
      // console.log('removing');
      Composite.remove(engine.world, b);
    }
  }
  update();
}
window.addEventListener('click', randDot);
window.addEventListener('touchstart', randDot);

setTimeout(() => {
  setInterval(randDot, 500);
}, 3000);
